import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTHENTICATOR_ENABLED } from '../../common/shared';
import { IAuthenticatorEnabled } from '../../interfaces';


export interface EnableAuthenticatorState{
    loading: boolean,
    error?: any,
    content: IAuthenticatorEnabled,    
}

const initialState: EnableAuthenticatorState = {
    loading: false,
    error: null,
    content: AUTHENTICATOR_ENABLED,    
}

export const enableAuthenticatorAppSlice = createSlice({
    name: "enable-authenticator-app",
    initialState,
    reducers:{
        enableAuthenticatorApp: (state, action: PayloadAction<IAuthenticatorEnabled>) => {
            state.content = action.payload;
            state.loading = false;            
        },
        enableAuthenticatorAppPending: (state) => {
            state.loading = true;
        },
        enableAuthenticatorAppError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;            
        },
        enableAuthenticatorAppReset: (state) => {            
            state.loading = false;
            state.content = AUTHENTICATOR_ENABLED;            
            state.error = null;
        }        
    }
});

export const { enableAuthenticatorApp, enableAuthenticatorAppError, enableAuthenticatorAppPending, enableAuthenticatorAppReset } = enableAuthenticatorAppSlice.actions;
export default enableAuthenticatorAppSlice.reducer;