import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERIFY_LOGIN } from '../../common/shared';
import { IVerifyLogin } from '../../interfaces';



export interface VerifyLoginState{
    loading: boolean,
    error?: any,
    content: IVerifyLogin,    
}

const initialState: VerifyLoginState = {
    content: VERIFY_LOGIN,
    error: null,
    loading: false
};

export const verifyLoginSlice = createSlice({
    name: "verify-login",
    initialState,
    reducers:{
        verifyLoginDetails: (state, action: PayloadAction<IVerifyLogin>) => {
            state.content = action.payload;
            state.loading = false; 
            state.error = null;           
        },
        verifyLoginPending: (state) => {
            state.loading = true;
            state.error = null;
        },
        verifyLoginError: (state, action: PayloadAction<any>) => {            
            state.error = action.payload;
            state.loading = false;            
        },
        verifyLoginReset: (state) => {            
            state.loading = false;
            state.content = VERIFY_LOGIN;            
            state.error = null;
        }        
    }
});



export const { verifyLoginDetails, verifyLoginError, verifyLoginPending, verifyLoginReset } = verifyLoginSlice.actions;
export default verifyLoginSlice.reducer;