
import { createBrowserHistory } from 'history'
import {persistStore, persistCombineReducers, PersistConfig} from 'redux-persist';
import {createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import encryptSlice from './reducers/encryptSlice';
import autocompleteSlice from './reducers/autocompleteSlice';
import authenticatorCodeSlice from './reducers/authenticatorCodeSlice';
import enableAuthenticatorAppSlice from './reducers/enableAuthenticatorAppSlice';
import verifyLoginSlice from './reducers/verifyLoginSlice';
import loginSlice from './reducers/loginSlice';
import registerUserSlice from './reducers/registerUserSlice';
import userPreferenceSlice from './reducers/userPreferenceSlice';
import { IAppState } from './index';
import sessionStorage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

export const RESET_ALL = "RESET_ALL";

const

    persistConfig: PersistConfig<IAppState> = {
        key: "root",
        storage: sessionStorage,
        transforms:[
            // createWhitelistFilter("router",[]),                        
            createWhitelistFilter("autocompleteSlice", ["content"]),                       
            createWhitelistFilter("userPreferenceSlice", ["content"]),

            createBlacklistFilter("loginSlice", ["content"]),
            createBlacklistFilter("authenticatorCodeSlice", ["content"]),
            createBlacklistFilter("verifyLoginSlice", ["content"]),
            createBlacklistFilter("enableAuthenticatorAppSlice", ["content"]),
            createBlacklistFilter("registerUserSlice", ["content"]),
            createBlacklistFilter("encryptSlice", ["content", "error"])
        ]
    };

const
history = createBrowserHistory(),
appReducer = persistCombineReducers(persistConfig,{        
    // router: connectRouter(history) as Reducer,
    autocompleteSlice,
    loginSlice,
    encryptSlice,
    authenticatorCodeSlice,
    verifyLoginSlice,
    enableAuthenticatorAppSlice,
    registerUserSlice,
    userPreferenceSlice
}),
    rootReducer = (state: any, action: any) => {
        //console.log(`rootReducer`)       
        if(action.type===RESET_ALL){
            //Clear out state and session storage when a user cancels      
            state = undefined;
            localStorage.removeItem(`persist:root`);
        }


        return appReducer(state, action);
    },
    store = configureStore({
        reducer: rootReducer,
        middleware: [thunk] //https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/
        // middleware: (getDefaultMiddleware) =>
        //         getDefaultMiddleware().prepend(thunk), //this does not work with redux-persist you get a non-serializable error.          
    }),
    persistor = persistStore(store, undefined,() =>{});

    export type AppDispatch = typeof store.dispatch;
    export type RootState = ReturnType<typeof store.getState>;
    export default store;
    export {persistor, history, rootReducer }