import axios from 'axios'; 
import { AxiosResponse, AxiosError } from 'axios';
import { login, loginError, loginPending } from '../../state/reducers/loginSlice';
import { registerUser, registerUserPending, registerUserError } from '../../state/reducers/registerUserSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IApiFailure, IConfirmEmailRequest, IEnableAuthenticator, ILoginDetails, IRegisterUser, IVerifyLogin } from '../../interfaces';
import { enableAuthenticatorAppPending, enableAuthenticatorApp, enableAuthenticatorAppError } from '../../state/reducers/enableAuthenticatorAppSlice';

var API_URL = process.env.REACT_APP_IDENTITY

export function loginUser(loginDetails: ILoginDetails) {    
    const headers = {'Access-Control-Allow-Origin': 'https://achesoncrow.co.uk'};
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(loginPending());        
        axios.post(`${API_URL}/Authenticate/Login`, loginDetails,{headers})
        .then((res: AxiosResponse) => {
            dispatch(login(res.data.data));            
        }).catch((error: AxiosError) => {
            dispatch(loginError(error.response?.data))
        })
    }
}

export function verifyLogin(verifyLogin: IVerifyLogin) {    
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(loginPending());
        // console.log("verifyLogin API");
        axios.post(`${API_URL}/Authenticate/VerifyLogin`, verifyLogin)
        .then((res: AxiosResponse) => {
            // console.log(`verifyLogin response \r\n ${JSON.stringify(res.data.data)}`);
            dispatch(login(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(loginError(error.response?.data))
        })
    }
};

export function enableAuthenticator(code: IEnableAuthenticator) {           
    var config = {headers: { Authorization: `Bearer ${localStorage.getItem("token")}`}} 
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(enableAuthenticatorAppPending());
        axios.post(`${API_URL}/User/enableAuthenticator`,code ,config)
        .then((res: AxiosResponse) => {
            dispatch(enableAuthenticatorApp(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(enableAuthenticatorAppError(error.response?.data))
        })
    }
};

export function registerNewUser(register: IRegisterUser) {    
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(registerUserPending());
        axios.post(`${API_URL}/User/registerUser`, register)
        .then((res: AxiosResponse) => {
            dispatch(registerUser(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(registerUserError(error.response?.data as IApiFailure))
        })
    }
};

export function confirmEmail(request: IConfirmEmailRequest){
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(loginPending());
        axios.post(`${API_URL}/User/confirmEmail`, request)
        .then((res: AxiosResponse) => {
            dispatch(login(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(loginError(error.response?.data))
        })
    }
}

