import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER } from '../../common/shared';
import { IUser } from '../../interfaces';


export interface LoginState{
    loading: boolean,
    error?: any,
    content: IUser,
    isLoggedIn: boolean
}

const initialState: LoginState = {
    loading: false,
    error: null,
    content: USER,
    isLoggedIn: false
}

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers:{
        login: (state, action: PayloadAction<IUser>) => {
            var user = action.payload as IUser;
            localStorage.setItem("token", user.token);
            state.error = null;
            state.content = user as IUser;
            state.loading = false;
            state.isLoggedIn = true;
        },
        loginPending: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;
            state.isLoggedIn = false;
        },
        loginUpdate:(state, action: PayloadAction<IUser>) => {
            localStorage.removeItem("token");
            state.content = action.payload;
            state.loading = false;
            state.isLoggedIn = false;
            state.error = null;
        },
        loginReset: (state) => {
            localStorage.removeItem("token");
            state.loading = false;
            state.content = USER;
            state.isLoggedIn = false;
            state.error = null;
        }        


    }
});

export const { login, loginError, loginPending, loginUpdate, loginReset } = loginSlice.actions;
export default loginSlice.reducer;