import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import IsUserAuthenticated from '../VerifyLogin';
import Cookies from 'universal-cookie';
import { Container } from 'react-bootstrap';

interface IProps {  
  tagline?: string;
}

export default function NavigationBarExample(props: IProps) {

    const cookies = React.useMemo(() => new Cookies(),[]);
    var authenticated = IsUserAuthenticated();
    
    const handleLogout = () =>{
        cookies.remove(`login-email-address`);
        cookies.remove(`go-passwordless`);           
        localStorage.removeItem("token");
        localStorage.removeItem("persist:root");

        authenticated = IsUserAuthenticated();
    }

  return (        

    <div className='gen-header'>
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container className='header-container'>
                    <Navbar.Brand>
                        <Link to="/">
                            <span className="icon"><Icon name={faGears}/></span>
                            <span className="tagline">{props.tagline || ""}</span>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarResponsive" id="nav-toggle"/>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className='me-auto'>
                            <Nav.Item className={`nav-item ${authenticated ? "d-none" : "d-block"}`} >
                                <Link className="nav-link"
                                    to={'/login'}
                                    state={{ pageId: 'login' }}
                                >Sign in</Link>
                            </Nav.Item>
                            <Nav.Item className={`nav-item ${authenticated ? "d-none" : "d-block"}` } >
                                <Link className="nav-link"
                                    to={'/register'}
                                    state={{ pageId: 'register' }}
                                >Register</Link>
                            </Nav.Item>
                            <Nav.Item className={`nav-item ${authenticated ? "d-block" : "d-none"}`} >
                                <Link className="nav-link" to="/authenticator" >Account</Link>
                            </Nav.Item>
                            <Nav.Item className={`nav-item ${authenticated ? "d-block" : "d-none"}`} >
                                <Link className="nav-link" to="#" onClick={handleLogout} >Logout</Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
        </Navbar>
    </div>       
  );
};
