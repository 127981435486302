import React, {  useRef, useMemo } from 'react';
import { SpinnerComponent } from 'react-element-spinner';
import { Button, Form } from 'react-bootstrap';
import { IAppState } from '../../state';
import { getAuthenticationCode } from '../../api/Authentication';
import { enableAuthenticator } from '../../api/User';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import {IUser, IAuthenticatorCode, IAuthenticatorEnabled, IEnableAuthenticator} from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks'
import StandardHeaderLayout from '../Layout/standardHeader';
import './style.scss';
import QRCode from 'react-qr-code';

export default function EnableAuthenticatorForm() {

    const
        dispatch = useAppDispatch(),
        user: {content: IUser, error?: any, loading: boolean, isLoggedIn: boolean} = useAppSelector(({ loginSlice }: IAppState) => loginSlice),
        authenticatorCode: {content: IAuthenticatorCode, error?: any, loading: boolean} = useAppSelector(({ authenticatorCodeSlice }: IAppState) => authenticatorCodeSlice),
        authenticator: {content: IAuthenticatorEnabled, error?: any, loading: boolean} = useAppSelector(({ enableAuthenticatorAppSlice }: IAppState) => enableAuthenticatorAppSlice),
        [data, setData] = React.useState<IEnableAuthenticator>({provider: "Authenticator", code: "", passwordless: false }),
        [warning, setWarning] = React.useState(""),
        [goPasswordless, setGoPasswordless] = React.useState(false),
        cookies = useMemo(() => new Cookies(),[]),
        runOnce = useRef(false),
        navigate = useNavigate();

    
    React.useEffect(() => {
        if(!runOnce.current){
            if(user.content.username !=="" && user.content.username !== undefined){                    
                let token = localStorage.getItem("token");
                if(token && token !== undefined && !user.content.twoStepAuthenticationIsRequired){   
                    dispatch(getAuthenticationCode(token));                     
                    runOnce.current=true;
                }
            }
            // else if(user.error !== null){
            //     setWarning("Invalid token.");
            // }
        }
    }, [user, navigate, dispatch]);

    React.useEffect(() => {
        if(authenticator.content.isEnabled){
            navigate("/authenticator-enabled");
        }
        else{
            setWarning("Unable to verify your authenticator app");
        }

    },[authenticator.content, navigate])

    const handleSubmission = () => { 
        const current = new Date();
        const expires = new Date();
        expires.setDate(current.getFullYear() + 5 );
        const maxAge = 5 * 365 * 86400; 
        //Have to set email as this will be used to verify the login if the user is passwordless
        if(goPasswordless){
            cookies.set(`login-email-address`,user.content.username, {path: '/',expires, maxAge: maxAge});       
            cookies.set(`go-passwordless`,"true", {path: '/',expires, maxAge: maxAge});
        }
        dispatch(enableAuthenticator(data));
    },    
    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {                    
        if (e.key === "Enter")           
                handleSubmission();
    },
    handleGoPasswordlessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGoPasswordless(e.currentTarget.checked);
        setData({
            passwordless: e.currentTarget.checked,
            provider: data.provider,
            code: data.code
        });
    };

    return (
        <StandardHeaderLayout header='Manage Account'>
            <SpinnerComponent
                loading={user.loading}
                position='centered'
                message="Verifying your account details..."
            />
            <div className="text-danger lead font-weight-bold">{warning}</div>            
            <div className={authenticatorCode.content.code!==""?"show-code": "hide-code"}>
                <h3>Configure Authenticator App</h3>
                <div>
                    <p>To use an authenticator app go through the following steps:</p>
                    <ol className='list'>
                    <li>
                    <p>
                        Download a two-factor authenticator app like Microsoft Authenticator for&nbsp; 
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825072">Android</a> and&nbsp; 
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825073">iOS</a> or
                        Google Authenticator for
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">Android</a> and
                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>.
                </p>
                    </li>
                    <li>
                    <p>Scan the QR Code or enter this key <kbd>{authenticatorCode.content.code}</kbd> into your two factor authenticator app. Spaces and casing do not matter.</p>
                    <div className='p-3'>
                    <QRCode value={authenticatorCode.content.qrCodeUri}></QRCode>
                    </div>                        
                    </li>
                    <li>
                        <p>
                            Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
                            with a unique code. Enter the code in the confirmation box below.
                        </p>
                        <div>
                            <Form onSubmit={handleSubmission}>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        placeholder='Verification Code'
                                        required
                                        size="lg"
                                        type="text"
                                        id="token"
                                        onChange={e => setData({                                            
                                            provider: "Authenticator",
                                            code: e.target.value,
                                            passwordless: goPasswordless
                                        })}
                                    />
                                </Form.Group>
                                <Form.Group>
                        <div>
                            <Form.Check                                
                                inline
                                label="Go Passwordless?"
                                onKeyPress={handleKeyPress}
                                checked={goPasswordless}
                                onChange = {handleGoPasswordlessChange}                           
                            />                                
                        </div>
                        </Form.Group>
                                <div className='flexbox grid button-padding' >
                                    <Button variant="primary" size="lg" className="btn-block me-2" type="button" onClick={e => {e.preventDefault(); e.stopPropagation(); handleSubmission()}}>Verify</Button>
                                </div>
                            </Form>
                        </div>
                    </li>
                    </ol>
                </div>
            </div>
        </StandardHeaderLayout>
    );

};
