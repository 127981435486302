import LoginForm from '../../components/LoginForm';
import MainLayout from '../../components/Layout/mainLayout';



export default function Login(){

  return(             
  <MainLayout>
    <LoginForm />
  </MainLayout>
  )  

}