import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTHENTICATOR_CODE } from '../../common/shared';
import { IAuthenticatorCode } from '../../interfaces';


export interface AuthenticatorCodeState{
    loading: boolean,
    error?: any,
    content: IAuthenticatorCode,    
}

const initialState: AuthenticatorCodeState = {
    loading: false,
    error: null,
    content: AUTHENTICATOR_CODE,    
}

export const authenticatorSlice = createSlice({
    name: "authenticator-code",
    initialState,
    reducers:{
        authenticatorCode: (state, action: PayloadAction<IAuthenticatorCode>) => {
            state.content = action.payload;
            state.loading = false;            
        },
        authenticatorCodePending: (state) => {
            state.loading = true;
        },
        authenticatorCodeError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;            
        },
        authenticatorCodeReset: (state) => {            
            state.loading = false;
            state.content = AUTHENTICATOR_CODE;            
            state.error = null;
        }        
    }
});

export const { authenticatorCode, authenticatorCodePending, authenticatorCodeError, authenticatorCodeReset } = authenticatorSlice.actions;
export default authenticatorSlice.reducer;