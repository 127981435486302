import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_PREFERENCE, USER_PREFERENCE_RESPONSE} from '../../common/shared';
import { IUserPreferenceResponse, IUserPreference } from '../../interfaces'; 
import { getPreferences } from 'api/Preferences';

export interface UserPreferenceState{
    loading: boolean,
    error?: any,
    updated: IUserPreferenceResponse,
    content: [IUserPreference]    
}

const initialState: UserPreferenceState = {
    updated: USER_PREFERENCE_RESPONSE,
    content: [USER_PREFERENCE],
    error: null,
    loading: false
};

export const userPreferenceSlice = createSlice({
    name: "user-preference",
    initialState,
    reducers:{
        userPreferencesGet: (state, action: PayloadAction<[IUserPreference]>) => {
            state.content = action.payload;
            state.loading = false;            
        },        
        userPreferenceUpdate: (state, action: PayloadAction<IUserPreferenceResponse>) => {
            state.updated = action.payload as IUserPreferenceResponse;
            state.loading = false;            
        },
        userPreferenceUpdateReset: (state) => {
            state.updated = USER_PREFERENCE_RESPONSE;
            state.loading = false;            
        },
        userPreferencePending: (state) => {
            state.loading = true;
        },
        userPreferenceError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
            state.loading = false;            
        },
        userPreferenceReset: (state) => {            
            state.loading = false;
            state.updated = USER_PREFERENCE_RESPONSE;
            state.content = [USER_PREFERENCE];
            state.error = null;
        }        
    },
    extraReducers: (builder) => {
        builder.addCase(getPreferences.pending, (state)=> {
            state.loading = true;
            state.content = [USER_PREFERENCE];
            state.error = null;
        });
        builder.addCase(getPreferences.fulfilled, (state, action)=> {
            state.content = action.payload.data;
            state.loading = false;
            state.error = null;                    
        });  
        builder.addCase(getPreferences.rejected, (state, action)=> {
            state.error = action.payload;
            state.loading = false;
            state.content = [USER_PREFERENCE];
        });  
    }
});



export const { userPreferencesGet, userPreferenceUpdate, userPreferenceUpdateReset, userPreferencePending, userPreferenceError, userPreferenceReset } = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;