import React from 'react';
import { IAppState } from '../../state';
import {IAuthenticatorEnabled} from '../../interfaces';
import { enableAuthenticatorAppReset } from '../../state/reducers/enableAuthenticatorAppSlice'
import StandardHeaderLayout from '../Layout/standardHeader';
import { useAppSelector, useAppDispatch } from '../../hooks'
import './style.scss';

export default function AuthenticatorVerified() {

    const
        authenticator: {content: IAuthenticatorEnabled, error?: any, loading: boolean} = useAppSelector(({ enableAuthenticatorAppSlice }: IAppState) => enableAuthenticatorAppSlice),
        [data, setData] = React.useState<IAuthenticatorEnabled>({isEnabled: false, recoveryCodes:[""]}),
        dispatch=useAppDispatch();
    
    React.useEffect(() => {
        if(authenticator.content.isEnabled){
            setData(authenticator.content);
            dispatch(enableAuthenticatorAppReset());
        }

    },[authenticator.content, dispatch])

    return (
        <StandardHeaderLayout header='Manage Account'>
            <div className="mb-2 alert alert-success">Your authenticator app has been verified</div>            
            <div>
                <h3>Recovery Codes</h3>
                <div className='alert alert-warning'>
                <p>
                    <strong>Put these codes in a safe place.</strong>
                </p>
                <p>
                    If you lose your device and don't have the recovery codes you will lose access to your account.
                </p>
                </div>
                <div>
                    <ul className='list-unstyled'>
                        {data.recoveryCodes.map(r => (<li key={r}>{r}</li>))}
                    </ul>
                </div>
            </div>
        </StandardHeaderLayout>
    );

};
