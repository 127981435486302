import React from 'react';
import { IAppState } from '../../state';
import { useLocation, useNavigate } from 'react-router-dom';
import {IUser, IConfirmEmailRequest} from '../../interfaces';
import { loginReset } from '../../state/reducers/loginSlice';
import { confirmEmail } from '../../api/User'
import StandardHeaderLayout from '../Layout/standardHeader';
import { useAppSelector, useAppDispatch } from '../../hooks'
import { Link } from 'react-router-dom';

export default function EmailConfirmation() {

    const
        user: {content: IUser, error?: any, loading: boolean, isLoggedIn: boolean} = useAppSelector(({ loginSlice }: IAppState) => loginSlice),   
        [warning, setWarning] = React.useState(""),
        [message, setMessage] = React.useState(""),     
        dispatch=useAppDispatch(),
        location = useLocation(),
        navigate = useNavigate(),
        params = new URLSearchParams(location.search),        
        runOnce = React.useRef(false),
        confirmEmailRequest = React.useRef<IConfirmEmailRequest>({userId:params.get('userid'), code: params.get('code')})

        React.useEffect(() => { 
            if(!runOnce.current){                
                if(!confirmEmailRequest.current.userId || !confirmEmailRequest.current.code){
                    setWarning("Your email cannot be confirmed & registration has not been completed.");
                }
                else{                    
                    dispatch(confirmEmail(confirmEmailRequest.current));
                    runOnce.current = true;
                }        
            }
            else{
                dispatch(loginReset());
                runOnce.current=true;
            }
        },[dispatch]);

        React.useEffect(() => {
            if(runOnce.current){       
                if(user.content.username !=="" && user.content.username !== undefined){                                        
                    setMessage("Thankyou for confirming your email.")
                }
                else if(user.error !== null){
                    setWarning("Email or password not recognised.");
                }        
            }
            else{
                dispatch(loginReset());
                runOnce.current=true;
            }
        }, [user, navigate, dispatch])

    return (
        <StandardHeaderLayout header='Manage Account'>
            <div className={warning===""? "d-none" : "mb-2 alert alert-warning"}>{warning}</div>
            <div className={message===""? "d-none" : "mb-2 alert alert-success"}>{message}</div>            
            <div className={user.content.token.length>0 ? "d-block" : "d-none"}>
                <h3>Change account settings</h3>
                <div className='alert alert-warning'>
                <p>
                    <strong>Two Factor Authentication.</strong>
                </p>
                <p>
                    To protect your account you can set up an authenticator app. You can also set your authenticator to allow you passwordless login using a one time code.
                </p>
                <p>
                    Download a two-factor authenticator app like Microsoft Authenticator for
                    <a href="https://go.microsoft.com/fwlink/?Linkid=825072">Android</a> and
                    <a href="https://go.microsoft.com/fwlink/?Linkid=825073">iOS</a> or
                    Google Authenticator for
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">Android</a> and
                    <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a>.
            </p>
                </div>
                <div>
                    <ul className='list-unstyled'>
                        <li><Link to="/authenticator" title="Set up Authenticator app">Set up Authenticator</Link></li>                        
                        <li><Link to="/">Start Generator</Link></li>
                    </ul>
                </div>
            </div>
        </StandardHeaderLayout>
    );

};
