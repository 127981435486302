import MainLayout from '../../components/Layout/mainLayout';
import EmailConfirmation from '../../components/EmailConfirmation';



export default function ConfirmEmail(){

  return(
    <MainLayout>
      <EmailConfirmation />
    </MainLayout>
  )  

}