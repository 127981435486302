import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REGISTER_USER_RESPONSE } from '../../common/shared';
import { IRegisterUserReponse, IApiFailure } from '../../interfaces';


export interface RegisterUserState{
    loading: boolean,    
    error?: IApiFailure | null,
    content: IRegisterUserReponse    
}

const initialState: RegisterUserState = {
    loading: false,
    error: null,    
    content: REGISTER_USER_RESPONSE
}

export const registerUserSlice = createSlice({
    name: "register-user",
    initialState,
    reducers:{
        registerUser: (state, action: PayloadAction<IRegisterUserReponse>) => {            
            state.content = action.payload as IRegisterUserReponse;
            state.loading = false;            
        },
        registerUserPending: (state) => {
            state.loading = true;
        },
        registerUserError: (state, action: PayloadAction<IApiFailure>) => {
            state.error = action.payload;
            state.loading = false;            
        },
        registerUserReset: (state) => {            
            state.loading = false;
            state.content = REGISTER_USER_RESPONSE;
            state.error = null;
        }        


    }
});

export const { registerUser,registerUserError,registerUserPending,registerUserReset } = registerUserSlice.actions;
export default registerUserSlice.reducer;