import Layout from "../../components/Layout/formHeader";
import MainLayout from '../../components/Layout/mainLayout';
import './style.scss';



export default function How()
{    
        return(            
                <MainLayout>
                    <Layout header="How does it work?">
                    <div className="grid">
                    <div className="col-m-12">
                    <h4>History</h4>
                    <p>
                        The website started way back in 2008 as a private project to enable Charlie to get passwords for any of the websites that he used without actually having to
                        remember anything due to having a brain resembling a swiss cheese.
                    </p>
                    <h4>The Concept</h4>
                    <p>
                    
                        The concept behind the website was to generate passwords that would be created using the name of the website plus a secure password, the idea is that
                        every website you use will have a different password, whilst you only need to remember one.  As long as you never write it down or use it anywhere else then it remains
                        very secure.
                    </p>
                    <p>
                        If one of the unwashed hack into <i>www.wekeepyourpasswordsinplaintext.com</i> which you use to buy new net curtains for your Nan then they may, indeed, be able to get your
                        password but crucially they won't be able to use it for anything else.
                    </p>
                    <h4>How</h4>
                    <p>
                        We've tried to keep it as simple as possible.
                    </p>
                    <ul>
                        <li>Enter the name of the website in the <i>Text</i> box</li>
                        <li>Enter your own secure password in the <i>Password</i> box. <span className="small-text">N.B This password is never saved</span></li>
                        <li>Check you've entered the your password correctly using the eye <i className="far fa-eye"></i></li>
                        <li>Hit <i>Enter</i> or click <i>Encrypt</i></li>
                        <li>Your new password will appear at the top with a <i className="far fa-copy"></i>Copy icon next to it. Clicking this will copy your new password to the clipboard ready
                        to paste into the website password field</li>
                    </ul>
                    <p>
                        The way we use it is to enter the name of the website plus the domain so we'd enter <i>amazon.co.uk</i> for instance, or <i>microsoft.com</i>.  You can use whatever you like
                        just make sure that you always use the same name.  It's best to do the same for all sites so that you have as little as possible that you need to remember...just saying ;-).
                    </p>
                    <p>
                        You can adjust the length of the password if need be, we have set it to default to 12 currently but you can choose a password length to suit the site requirements.
                    </p>
                    <p>
                        Some sites have specific requirements for passwords and we have tried to include all of them that we have encountered.  For instance, there is a possiblity that you
                        may need a password for a site that only allows letters and numbers in it's passwords, if the password you have generated has no numbers then
                        tick the Numbers check box to ensure that the password has numbers, special characters etc etc.
                    </p>
                    <p>Enjoy!</p>
                    <p>P.S. There is of course one caveat, you still have to remember your own secure password, if you forget that then I'm afraid you are on your own :-)</p>
                    </div>
                    </div>
                    </Layout>
                </MainLayout>
        );
}