import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AutocompleteState{
    loading: boolean,
    error?: any,
    content: string[]
}

const initialState: AutocompleteState ={
    loading: false,
    error: null,
    content: [""]
};

export const autocompleteSlice = createSlice({
    name: 'autocomplete',
    initialState,
    reducers:{
        setAutocomplete: (state, action: PayloadAction<string[]>) => {
            state.content = action.payload;
            state.loading = false;
        }
    }
});

export const { setAutocomplete} = autocompleteSlice.actions;
export default autocompleteSlice.reducer;

