import * as React from 'react';
import "./style.scss";

export interface IStandardHeaderLayoutProps {
    header?: string, 
    children: React.ReactNode;
}

export default function StandardHeaderLayout (props: IStandardHeaderLayoutProps) {

    return (
        <div className="gen-layout">
        <div className="img-header">
            <div className="container flexbox">
                <div className="grid">
                    <div className="all-hide m-show col-m-12">
                        
                    </div>
                    <div>  
                        <h2>{(props.header !== null && props.header !== undefined) && props.header}</h2>
                    </div>
                    <div className="col-6 m-hide" id="empty">
                        
                    </div>
                </div>
            </div>
        </div>       
        <div className="container flexbox">
            {props.children}
        </div>
    </div>  
    );
}