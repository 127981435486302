import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './features/Home';
import CookiePolicy from './features/CookiePolicy';
import PrivacyPolicy from './features/PrivacyPolicy';
import Login from './features/Login';
import Authenticator from './features/Authenticator';
import AuthenticatorEnabled from './features/Authenticator/enabled';
import VerifyLogin from './features/VerifyLogin';
import Register from './features/Register';
import ConfirmEmail from './features/ConfirmEmail';
import How from './features/How';
import ScrollToTop from './components/ScrollToTop';

const Router = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <ScrollToTop />                    
            <Routes>
                 <Route path="/" element={<Home />}/>
                 <Route path="/cookie-policy" element={<CookiePolicy />}/>
                 <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                 <Route path="/how-does-it-work" element={<How />}/>
                 <Route path="/register" element={<Register />}/>
                 <Route path="/login" element={<Login />}/>
                 <Route path="/verify-login" element={<VerifyLogin />}/>
                 <Route path="/authenticator" element={<Authenticator />}/>
                 <Route path="/confirm-email" element={<ConfirmEmail />}/>
                 <Route path="/authenticator-enabled" element={<AuthenticatorEnabled />}/>
                 <Route path="*" element={<Navigate to="/" />}/>
            </Routes>        
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Router;