import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EncryptionState{
    loading: boolean,
    error?: any,
    content: string
}


const initialState: EncryptionState = {
    loading: false,
    error: null,
    content: ""
};

export const encryptSlice = createSlice({
    name: 'encrypt',
    initialState,
    reducers:{
        encrypt: (state, action: PayloadAction<string>) => {
            state.content = action.payload;
            state.loading = false;            
        },
        pending: (state) => {
            state.loading = true;            
        },
        encryptError: (state, action: PayloadAction<any>) =>{
            state.error = action.payload;
            state.loading = false;
        },
        encryptReset: (state) => {
            state.content="";
            state.error=null;
            state.loading=false;
        }
        
    }
});

export const { encrypt, pending, encryptError, encryptReset } = encryptSlice.actions;
export default encryptSlice.reducer;