import {  IAuthenticatorCode, IAuthenticatorEnabled, IEncryptValues, ILoginDetails, IRegisterUser, IRegisterUserReponse, IUser, IUserPreference, IUserPreferenceResponse, IVerifyLogin, IConfirmEmailRequest } from "../interfaces";

export const ENCRYPT_VALUES: IEncryptValues ={
    text: "",
    password: "",
    password2: "",
    length: 12,
    excludeSymbol: false,
    includeCase: false,
    includeNumber: false,
    includeSymbol: false
};

export const USER_PREFERENCE: IUserPreference = {
    userName: "",
    inputText: "",
    includeNumber: false,
    includeCase: false,
    includeSymbol: false,
    excludeSymbol: false,
    length: 12,
    passwordIteration: 12,
}

export const USER_PREFERENCE_RESPONSE: IUserPreferenceResponse = {
    success: false
}

export const USER: IUser = {
    provider: "",
    twoStepAuthenticationIsRequired: false,
    username: "",
    token: "",
    expiration: ""
};

export const AUTHENTICATOR_CODE: IAuthenticatorCode = {
    code: "",
    qrCodeUri: ""
};

export const VERIFY_LOGIN: IVerifyLogin = {
    provider: "",
    token: "",
    username: "",
    passwordless: false
};

export const LOGIN_DETAILS: ILoginDetails = {
    username: "",
    password: "",
    authProvider: null
};

export const AUTHENTICATOR_ENABLED: IAuthenticatorEnabled = {
    isEnabled: false,
    recoveryCodes: [""]

};

export const REGISTER_USER: IRegisterUser = {
    active: "true",
    role: "User",
    email: "",
    password: ""
};

export const REGISTER_USER_RESPONSE: IRegisterUserReponse = {
    active: false,
    email: "",
    firstname: "",
    lastname: "",
    username: ""
}

export const CONFIRM_EMAIL_REQUEST: IConfirmEmailRequest = {
    code: null,
    userId: null
}