import * as React from 'react';
import Footer from '../Footer';
import NavigationBar from '../NavigationBar';
import Content from '../../common/Content';

type MainLayoutProps ={
    children: React.ReactNode;
    tagline?: string | null
};


const MainLayout: React.FunctionComponent<MainLayoutProps> = ({children, tagline}) => {
    return (
        <div className='page-container'>
            <NavigationBar tagline={!tagline ? Content.Header.tagline : tagline}></NavigationBar>
                {children}
            <Footer/>
        </div>
    )
};

export default MainLayout;