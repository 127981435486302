import React, {  useRef } from 'react';
import { SpinnerComponent } from 'react-element-spinner';
import { Button, Form } from 'react-bootstrap';
import { IAppState } from '../../state';
import { registerNewUser } from '../../api/User';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import {IApiFailure, IRegisterUser, IRegisterUserReponse} from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks'
import { registerUserReset } from '../../state/reducers/registerUserSlice'
import StandardHeaderLayout from '../Layout/standardHeader';
import { REGISTER_USER } from '../../common/shared';

export default function RegisterUserForm() {

    const
        dispatch = useAppDispatch(),
        register: {content: IRegisterUserReponse, error?: IApiFailure | null, loading: boolean} = useAppSelector(({ registerUserSlice }: IAppState) => registerUserSlice),
        [data, setData] = React.useState<IRegisterUser>(REGISTER_USER),
        [message, setMessage] = React.useState(""),
        [warning, setWarning] = React.useState(""),
        runOnce = useRef(false),
        cookies = React.useMemo(()=> new Cookies(), []),
        activeFieldRef = React.useRef<HTMLInputElement>(null),        
        navigate = useNavigate();

    React.useEffect(() =>{
        activeFieldRef.current && activeFieldRef.current.focus();
    },[])        

    
    React.useEffect(() => { 
        if(runOnce.current){       
            if(register.content.username !=="" && register.content.username !== undefined){                                                    
                setMessage("User registered successfully. Please sign in.")
                setData(REGISTER_USER);
            }
            else if(register.error !== null){
                let errors = ``;
                    register.error && register.error.Errors.forEach(e => {
                        errors = errors + e + `\n`;
                    })
                
                var errorText = errors===`` ? "Unable to register user." : errors; 
                setWarning(errorText);
            }        
        }
        else{
            dispatch(registerUserReset());
            runOnce.current=true;
        }
    }, [register, navigate, dispatch]);

    const handleSubmission = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        cookies.remove(`login-email-address`);
        cookies.remove(`go-passwordless`);              
        localStorage.removeItem("token");
        localStorage.removeItem("persist:root");
        dispatch(registerNewUser(data));
    };

    return (
        <StandardHeaderLayout header='Register'>
            <SpinnerComponent
                loading={register.loading}
                position='centered'
                message="Registering your account..."
            />
            <div className="text-danger font-weight-bold">{warning}</div>
            <div className={register.content.active?"d-block mb-2 alert alert-success" : "d-none"}>{message}</div>
            <div>
                <Form onSubmit={handleSubmission}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            placeholder='Username or Email address'
                            required
                            size="lg"
                            type="text"
                            id="userName"
                            value={data.email || ""}
                            ref = {activeFieldRef}
                            onChange={e => setData({
                                active: "true",
                                role: "User",
                                email: e.target.value,
                                password: data.password
                            })}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Control
                            placeholder="Password"
                            type="password"
                            required
                            size="lg"
                            value={data.password || ""}                            
                            onChange={e => setData({
                                active: "true",
                                role: "User",
                                email: data.email,
                                password: e.target.value

                            })}
                        />
                    </Form.Group>
                    <div className='flexbox grid button-padding' >                        
                        <Button variant="primary" size="lg" className="btn-block me-2" type="submit">Register</Button>                        
                    </div>
                </Form>
            </div>
        </StandardHeaderLayout>
    );

};
