import React, {  useRef, useMemo } from 'react';
import { SpinnerComponent } from 'react-element-spinner';
import { Button, Form } from 'react-bootstrap';
import { IAppState } from '../../state';
import { verifyLogin } from '../../api/User';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import {IVerifyLogin, IUser} from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks'
import StandardHeaderLayout from '../Layout/standardHeader';

export default function VerifyLoginForm() {

    const
        dispatch = useAppDispatch(),
        user: {content: IUser, error?: any, loading: boolean, isLoggedIn: boolean} = useAppSelector(({ loginSlice }: IAppState) => loginSlice),
        [data, setData] = React.useState<IVerifyLogin>({username: "", provider: "Authenticator", token: "", passwordless: false}),
        [goPasswordless, setGoPasswordless] = React.useState(false),
        [warning, setWarning] = React.useState(""),
        runOnce = useRef(false),        
        tokenField = React.useRef<HTMLInputElement>(null),
        cookies = useMemo(() => new Cookies(),[]), 
        navigate = useNavigate();

    
    React.useEffect(() => {
        if(runOnce.current){

            if(user.content.username !=="" && user.content.username !== undefined && user.error === null){                                    
                let token = localStorage.getItem("token");                
                    if(token && token !== undefined && token !== 'null'){                        
                        navigate("/");
                    }
            }
            else if(user.error !== null){
                setWarning("Invalid token.");
                localStorage.removeItem("token")
                tokenField.current && tokenField.current.focus();
            }
        }
        else{
            var check = cookies.get("go-passwordless");
            setGoPasswordless(check==="true");
        }
    }, [user, navigate, cookies]);


    React.useEffect(() => {
        tokenField.current && tokenField.current.focus();
    },[])

    const handleSubmission = () => {
        if(data.username!=='' && data.token!==''){
            const current = new Date();
            const expires = new Date();
            expires.setDate(current.getFullYear() + 5 );
            const maxAge = expires.getTime();
            cookies.set("go-passwordless", goPasswordless ?'true':'false',{path:"/",expires, maxAge: maxAge });
            dispatch(verifyLogin(data));
            runOnce.current = true;
        }
        else{
            if(data.token === ''){
                setWarning("Please enter a One Time Password");
            }
         }

    },
    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => { 
   
        if (e.key === "Enter")//enter
        {
            e.preventDefault();
            e.stopPropagation();  
            if(data.username!=='' && data.token!==''){
                handleSubmission();
            }
            else{
                if(data.token===''){
                    setWarning("Please enter a One Time Password");
                }
                else if(data.username===''){
                    setWarning("No email address supplied");
                }
            }           
        }
    },
    handleGoPasswordlessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            username: data.username,
            provider: data.provider,
            token: data.token,
            passwordless: e.currentTarget.checked
        })
        setGoPasswordless(e.currentTarget.checked);
    };

    return (
        <StandardHeaderLayout>
            <SpinnerComponent
                loading={user.loading}
                position='centered'
                message="Verifying your account details..."
            />
            <div className="text-danger lead font-weight-bold">{warning}</div>
            <div>
                <Form>
                    <Form.Group className="mb-2">
                        <Form.Control
                            placeholder='Token'
                            required
                            size="lg"
                            type="text"
                            id="token"
                            ref={tokenField}
                            onKeyPress={handleKeyPress}
                            onChange={e => setData({
                                username: user.content.username,
                                provider: user.content.provider,
                                token: e.target.value,
                                passwordless: data.passwordless                                
                            })}
                        />
                    </Form.Group>       
                    <div>
                            <Form.Check                                
                                inline
                                label="Go Passwordless?"
                                onKeyPress={handleKeyPress}
                                checked={goPasswordless}
                                onChange = {handleGoPasswordlessChange}                           
                            />                                
                        </div>             
                    <div className='flexbox grid button-padding' >                        
                        <Button variant="primary" size="lg" className="btn-block me-2" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); handleSubmission() }}>Continue</Button>                        
                    </div>
                </Form>
            </div>
        </StandardHeaderLayout>
    );

};
