import axios from 'axios'; 
import { AxiosResponse, AxiosError } from 'axios';
import { authenticatorCodePending,authenticatorCode,authenticatorCodeError } from '../../state/reducers/authenticatorCodeSlice';
import { PayloadAction } from '@reduxjs/toolkit';

var API_URL = process.env.REACT_APP_IDENTITY

export function getAuthenticationCode(jwtToken: string) {   
    var config = {headers: { Authorization: `Bearer ${jwtToken}`}} 
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(authenticatorCodePending());
        axios.get(`${API_URL}/User/getAuthenticatorCode`, config)
        .then((res: AxiosResponse) => {
            dispatch(authenticatorCode(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(authenticatorCodeError(error.response?.data))
        })
    }
}



// 