import MainLayout from '../../components/Layout/mainLayout';
import EnableAuthenticatorForm from '../../components/EnableAuthenticatorForm';



export default function Authenticator(){

  return(
    <MainLayout>
      <EnableAuthenticatorForm />
    </MainLayout>
  )  

}