import EncryptForm from '../../components/EncryptForm';
import MainLayout from '../../components/Layout/mainLayout';



export default function Home(){

  return(
    <MainLayout>
      <EncryptForm />
    </MainLayout>    
  )  

}