import RegisterForm from '../../components/RegisterUserForm';
import MainLayout from '../../components/Layout/mainLayout';



export default function Register(){

  return(
        
    <MainLayout>
      <RegisterForm />
    </MainLayout>
    
  )  

}