import { useAppSelector } from '../../hooks'
import { IAppState } from '../../state';
import {IUser} from '../../interfaces';

export default function IsUserAuthenticated(): boolean{

    const         
        user: {content: IUser, error?: any, loading: boolean, isLoggedIn: boolean} = useAppSelector(({ loginSlice }: IAppState) => loginSlice),        
        token = localStorage.getItem("token");

    if((!token || token === undefined) || (user.content.expiration==='') || (user.content.username==='')){        
        return false;
    }    
    
    let today = new Date();
    let expiry = new Date(user.content.expiration);
    let authenticated = expiry > today;    
    return authenticated;
};