import MainLayout from '../../components/Layout/mainLayout';
import AuthenticatorVerified from '../../components/EnableAuthenticatorForm/authenticatorVerified'



export default function AuthenticatorEnabled(){

  return(
    <MainLayout>
      <AuthenticatorVerified />
    </MainLayout>
  )  

}