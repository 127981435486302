import * as React from 'react';
import FormHeaderLayout from "../Layout/formHeader";
import Content from "../../common/Content";
import { IEncryptValues, IUserPreference, IUserPreferenceResponse } from '../../interfaces'
import { getEncryption } from '../../api/Encryption';
import { IAppState } from '../../state';
import { Button, Form, InputGroup, Overlay, Popover, OverlayProps } from 'react-bootstrap';
import { ENCRYPT_VALUES } from '../../common/shared';
import { encryptReset } from '../../state/reducers/encryptSlice';
import { setAutocomplete } from '../../state/reducers/autocompleteSlice';
import { SpinnerComponent } from 'react-element-spinner';
import "./style.scss";
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getPreferences, updateUserPreference } from '../../api/Preferences';
import { useMemo, useCallback } from 'react';
import { userPreferenceReset } from 'state/reducers/userPreferenceSlice';

export default function EncryptForm() {

    //SETUP
    const
        dispatch = useAppDispatch(),        
        encryption: { content: string, error?: any, loading: boolean } = useAppSelector(({ encryptSlice }: IAppState) => encryptSlice),
        autocomplete: { content: string[], error?: any, loading: boolean } = useAppSelector(({ autocompleteSlice }: IAppState) => autocompleteSlice),
        preferences: { content: [IUserPreference], error?: any, loading: boolean, updated: IUserPreferenceResponse} = useAppSelector(({ userPreferenceSlice }: IAppState) => userPreferenceSlice),
        [result, setResult] = React.useState(Content.EncryptForm.header),
        [encryptValues, setEncryptValues] = React.useState<IEncryptValues>(ENCRYPT_VALUES),
        [showCopy, setShowCopy] = React.useState(false),
        [showOverlay, setShowOverlay] = React.useState(false),
        [target, setTarget] = React.useState(null),
        [toolTip, setTooltip] = React.useState({ title: "", text: "" }),
        [placement, setPlacement] = React.useState<OverlayProps["placement"]>("left"),
        [showPassword, setShowPassword] = React.useState(false),        
        [loginEmail, setLoginEmail] = React.useState(""),
        cnt = Content.EncryptForm,
        ref = React.useRef(null),
        runOnce = React.useRef(false),   
        textInputRef = React.useRef<HTMLInputElement>(null),                
        [autoOptions, setAutoOptions] = React.useState<any>([]),
        cookies = useMemo(() => new Cookies(),[]), 
        getLengthOptions = () => {
            const options: any = [];
            for (let x = 1; x <= 30; x++) {
                options.push(<option key={x}>{x}</option>);
            }
            return options;
        };

        
    //FUNCTIONS
    
    const 
    getLoginEmail = () => {
        var email = cookies.get(`login-email-address`);
        if(email === loginEmail) return;
        setLoginEmail(email);        
    },
    updateValues = (values: IEncryptValues) => {
        let newValues: IEncryptValues = {
            text: values.text,
            password: values.password,
            password2: values.password2,
            length: values.length,
            excludeSymbol: values.excludeSymbol,
            includeCase: values.includeCase,
            includeNumber: values.includeNumber,
            includeSymbol: values.includeSymbol
        };

        setEncryptValues(newValues);
    },
        handleSubmit = () => {
            onCopy();
            dispatch(getEncryption(encryptValues));
            const current = new Date();
            const expires = new Date();
            expires.setDate(current.getFullYear() + 5 );
            const maxAge = 5 * 365 * 86400;
            var textList = cookies.get(`text-input-list`);
            var textInput = textList === undefined ? encryptValues.text : textList.includes(encryptValues.text) ? textList : `${textList},${encryptValues.text}`;            
            dispatch(setAutocomplete(textInput.split(',')))            
            cookies.set('text-input-list', textInput, {path: '/',expires, maxAge: maxAge});
            cookies.set(`${encryptValues.text}`, `${encryptValues.length},${encryptValues.includeCase ? 1 : 0},${encryptValues.includeSymbol ? 1 : 0},${encryptValues.includeNumber ? 1 : 0},${encryptValues.excludeSymbol ? 1 : 0},1`, { path: '/' ,expires: expires, maxAge: maxAge});            
            if(loginEmail){
                                   
                let userPreference: IUserPreference = {
                inputText: encryptValues.text,
                excludeSymbol: encryptValues.excludeSymbol,
                includeCase: encryptValues.includeCase,
                includeNumber: encryptValues.includeNumber,
                includeSymbol: encryptValues.includeSymbol,
                length: encryptValues.length,
                passwordIteration: 0,
                userName: loginEmail
                };
                dispatch(updateUserPreference(userPreference));
            }

        },
        handleClear = () => {
            setEncryptValues(ENCRYPT_VALUES);
            setResult(Content.EncryptForm.header);
            setShowCopy(false);
            dispatch(encryptReset())
        },
        onCopy = () => {
            setEncryptValues(ENCRYPT_VALUES);
            textInputRef.current && textInputRef.current.focus();
        },
        handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {            
            if (e.key === "Enter")//enter
            {
                handleSubmit();
            }
        },
        handleTextBlur = () => {
            var autoCookies = cookies.get(`${encryptValues.text}`);

            var cookieArray = autoCookies ? autoCookies.split(','):['12','0','0','0','0','0'];
            var inputLength = setInputLength(cookieArray)
            var includeCase = cookieArray[1];
            var includeSymbol = cookieArray[2];
            var includeNumber = cookieArray[3];                        
            var excludeSymbol = cookieArray[4];
            let newValues: IEncryptValues = {
                text: encryptValues.text,
                password: encryptValues.password,
                password2: encryptValues.password2,
                length: inputLength,
                excludeSymbol: excludeSymbol === "1",
                includeCase: includeCase === "1",
                includeNumber: includeNumber === "1",
                includeSymbol: includeSymbol === "1"
            };
            setEncryptValues(newValues);
        },
        setInputLength = (cookieArray: string[]): number => {
            var cookieLength = cookieArray[0];
            var passwordLength = parseInt(cookieLength);
            return isNaN(passwordLength) ? 12 : passwordLength;
        },
        handleOverlay = (event: any, tipTitle: string, tipText: string, tipPlacement: OverlayProps["placement"]) => {
            const tip = { title: tipTitle, text: tipText }
            setTooltip({ ...tip });
            setShowOverlay(!showOverlay);
            setTarget(event.target)
            setPlacement(tipPlacement);
        },
        toggleShow = (show: boolean) => {
            setShowPassword(show);
        },
        getAutoCompleteOptions = useCallback(() => {
            const options: any = [];
            for (let x = 0; x < autocomplete.content.length; x++) {
                options.push(<option key={x} value={autocomplete.content[x]}/>);
            }
            return options;
        }, [autocomplete.content]);

    getLoginEmail();     

    //HOOKS
    React.useEffect(() => {
        if (!runOnce.current) {             
            var textList = cookies.get(`text-input-list`);
            var arr = textList ? textList.split(','): [];            
            dispatch(setAutocomplete(arr));
            runOnce.current = true;
        }
        else{            
            setAutoOptions(getAutoCompleteOptions); }
        
    }, [autocomplete, cookies, getAutoCompleteOptions, preferences.content, autocomplete.content, dispatch])

    React.useEffect(() => {    
       (async () => {        
        if((loginEmail && preferences.content.length===1) || (loginEmail && preferences.content.length>1 && preferences.updated.success===true)){                     
                dispatch(getPreferences(loginEmail));
        }})()      
                                 
    },[preferences.updated.success, loginEmail, preferences.content.length, dispatch])



    React.useEffect(()=> {
        if(preferences.updated.success){
            dispatch(userPreferenceReset());
        }
            
    },[preferences.updated.success, dispatch])


    React.useEffect(() => {                    
        if(loginEmail && preferences.content.length>1)
        {
            const current = new Date();
            const expires = new Date();
            expires.setDate(current.getFullYear() + 5 );
            const maxAge = 5 * 365 * 86400;
            const textArr: string[] = [];
            preferences.content.forEach(item => {
                var itemCookie = cookies.get(item.inputText);
                textArr.push(item.inputText);
                if(!itemCookie && item.inputText !==''){                
                    cookies.set(`${item.inputText}`, `${item.length},${item.includeCase ? 1 : 0},${item.includeSymbol ? 1 : 0},${item.includeNumber ? 1 : 0},${item.excludeSymbol ? 1 : 0},${item.passwordIteration}`, { path: '/' ,expires: expires, maxAge: maxAge});            
                }
            })

            if(textArr.length>1){
                cookies.set('text-input-list', textArr.toString(), {path: '/',expires, maxAge: maxAge});
                if(autocomplete.content.length === 0 || autocomplete.content.length < textArr.length){
                    dispatch(setAutocomplete(textArr));       
                }
            }
                        
            autocomplete.content.forEach(text =>{ 
                var autoCookies = cookies.get(`${text}`);
                var cookieArray = autoCookies ? autoCookies.split(','):['12','0','0','0','0','1'];
                var preferenceItem = preferences.content.find(x => x.inputText === text);
                var passwordLength = setInputLength(cookieArray)                                                           
                var length = isNaN(passwordLength) ? 12 : passwordLength;
                var includeCase = cookieArray[1];
                var includeSymbol = cookieArray[2];
                var includeNumber = cookieArray[3];
                var excludeSymbol = cookieArray[4];
                var iteration = cookieArray[5];
                let userPreference: IUserPreference = {
                    inputText: text,
                    excludeSymbol: excludeSymbol === "1",
                    includeCase: includeCase === "1",
                    includeNumber: includeNumber === "1",
                    includeSymbol: includeSymbol === "1",
                    length: length,
                    passwordIteration: parseInt(iteration),
                    userName: loginEmail
                }
                var updateItem = true;

                if(preferenceItem){
                    if(preferenceItem.excludeSymbol === userPreference.excludeSymbol
                        && preferenceItem.includeCase === userPreference.includeCase
                        && preferenceItem.includeNumber === userPreference.includeNumber
                        && preferenceItem.includeSymbol === userPreference.includeSymbol
                        && preferenceItem.length === userPreference.length
                        && preferenceItem.passwordIteration === userPreference.passwordIteration
                        ){                    
                        updateItem = false;}
                }

                if(updateItem && userPreference.inputText !==''){
                    cookies.set(`${userPreference.inputText}`, `${userPreference.length},${userPreference.includeCase ? 1 : 0},${userPreference.includeSymbol ? 1 : 0},${userPreference.includeNumber ? 1 : 0},${userPreference.excludeSymbol ? 1 : 0},${userPreference.passwordIteration}`, { path: '/' ,expires: expires, maxAge: maxAge});
                    dispatch(updateUserPreference(userPreference));
                }
                
                setAutoOptions(getAutoCompleteOptions);
            })      
        }          
                                 
    },[autocomplete.content, cookies, dispatch, getAutoCompleteOptions, preferences.content, loginEmail])

    React.useEffect(() => {
        var inputList = cookies.get('text-input-list');        
        var allCookies = cookies.getAll({doNotParse:true});            
        const textArr: string[] = [];
        Object.keys(allCookies).forEach((key)=>{
            if(key !=='login-email-address' && key !== 'text-input-list' && key !=='go-passwordless')
            textArr.push(key);
        });
        var inputListArr: string[] = []
        if(inputList)
            inputListArr = inputList.split(',');
        if(inputListArr.length <=0 && textArr.length>0){
            const expires = new Date();
            expires.setDate(expires.getFullYear() + 5 );
            cookies.set('text-input-list', textArr.toString(), {path: '/',expires});      
            dispatch(setAutocomplete(textArr));      
        }                
        
    }, [cookies, autocomplete.content, dispatch])

    React.useEffect(() => {
        if (encryption.content.length > 0) {
            setResult(encryption.content);
            setShowCopy(true);
            onCopy();
        }
        else {
            setResult(Content.EncryptForm.header)
            setShowCopy(false);
        }
    }, [encryption.content])
    
    React.useEffect(() => {
        textInputRef.current && textInputRef.current.focus();
    }, [textInputRef])

    return (
        <FormHeaderLayout
            header={result}
            onCopy={onCopy}
            showCopy={showCopy}
        >
            <SpinnerComponent
                loading={encryption.loading}
                position='global'
                message="Encrypting your password..."
            />

            <div ref={ref}>                
                <Form onSubmit={handleSubmit} autoComplete='on'>                    
                    <div className='mb-3'>
                        <Form.Group >                            
                            <InputGroup className='vertical-align'>
                                <Form.Control
                                    tabIndex={1}
                                    type="text"
                                    name="text-input"
                                    id="text-input"
                                    size='lg'
                                    onChange={e => updateValues({
                                        text: e.target.value,
                                        password: encryptValues.password,
                                        password2: "",
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                    onBlur={e => { e.preventDefault(); e.stopPropagation(); handleTextBlur() }}                                    
                                    value={encryptValues.text}
                                    autoComplete="off"
                                    placeholder='Text'                                    
                                    ref={textInputRef}
                                    title={cnt.text}
                                    list='autocomplete'
                                />
                                <datalist id='autocomplete'>
                                    {autoOptions}
                                </datalist>
                                <div><i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Text", cnt.text, "left") }} /></div>
                                
                            </InputGroup>                            
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <InputGroup>
                            <Form.Control
                                tabIndex={2}
                                type={showPassword ? "text" : "password"}
                                size='lg'
                                onBlur={e => { e.preventDefault(); toggleShow(false) }}
                                onChange={e => updateValues({
                                    text: encryptValues.text,
                                    password: e.target.value,
                                    password2: "",
                                    length: encryptValues.length,
                                    excludeSymbol: encryptValues.excludeSymbol,
                                    includeCase: encryptValues.includeCase,
                                    includeNumber: encryptValues.includeNumber,
                                    includeSymbol: encryptValues.includeSymbol
                                })}
                                onKeyPress={handleKeyPress}
                                value={encryptValues.password}
                                placeholder='Password'
                                className='mb-3 password'
                                aria-describedby="buttonShow"
                                autoComplete='off'
                                id="password"
                                name="password"
                                required
                            />
                            <div className="input-group-append">
                                <button type="button" id="buttonShow" name="buttonShow" onClick={e => { e.preventDefault(); toggleShow(!showPassword) }} className={showPassword ? "las la-eye-slash" : "las la-eye"} /></div>
                            <i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Password", cnt.password, "left") }} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Length</Form.Label>
                        <InputGroup className='small-select'>
                            <Form.Select
                                tabIndex={3}
                                size='lg'
                                value={encryptValues.length}
                                onChange={e => updateValues({
                                    text: encryptValues.text,
                                    password: encryptValues.password,
                                    password2: encryptValues.password2,
                                    length: parseInt(e.target.value),
                                    excludeSymbol: encryptValues.excludeSymbol,
                                    includeCase: encryptValues.includeCase,
                                    includeNumber: encryptValues.includeNumber,
                                    includeSymbol: encryptValues.includeSymbol
                                })}>
                                {getLengthOptions()}
                            </Form.Select>
                            <i className="las la-info-circle la-lg ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Length", cnt.length, "right") }} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Label>Password must contain:
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={4}
                                    inline
                                    label="Numbers"
                                    checked={encryptValues.includeNumber}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: e.currentTarget.checked,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Numbers", cnt.includeNumber, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={5}
                                    inline
                                    label="Upper and lower case"
                                    checked={encryptValues.includeCase}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: e.currentTarget.checked,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeSymbol
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Upper and lower", cnt.includeCase, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={6}
                                    inline
                                    label="Special characters"
                                    checked={encryptValues.includeSymbol}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: encryptValues.excludeSymbol,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: e.currentTarget.checked
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "Special characters", cnt.includeSymbol, "top") }} />
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <Form.Check
                                    tabIndex={7}
                                    inline
                                    label="No special characters"
                                    checked={encryptValues.excludeSymbol}
                                    onChange={e => updateValues({
                                        text: encryptValues.text,
                                        password: encryptValues.password,
                                        password2: encryptValues.password2,
                                        length: encryptValues.length,
                                        excludeSymbol: e.currentTarget.checked,
                                        includeCase: encryptValues.includeCase,
                                        includeNumber: encryptValues.includeNumber,
                                        includeSymbol: encryptValues.includeNumber
                                    })}
                                />
                                <i className="las la-info-circle ms-1" onClick={e => { e.preventDefault(); handleOverlay(e, "No special characters", cnt.excludeSymbol, "top") }} />
                            </div>
                        </Form.Group>
                    </Form.Label>
                    <div className='flexbox grid button-padding'>
                        <Button 
                            tabIndex={8}
                            variant='primary' 
                            size='lg' 
                            type='button' 
                            onClick={e => { e.preventDefault(); e.stopPropagation(); handleSubmit() }} 
                            className='me-2'
                            >Encrypt</Button>
                                
                        <Button 
                            tabIndex={9}
                            variant='secondary' 
                            size='lg' 
                            type='button' 
                            onClick={handleClear}
                            >Cancel</Button>
                    </div>
                </Form>
                <Overlay
                    show={showOverlay}
                    target={target}
                    placement={placement}
                    container={ref}
                    containerPadding={20}
                >
                    <Popover id="popover-contained">
                        <Popover.Header as="h3">{toolTip.title}</Popover.Header>
                        <Popover.Body>
                            {toolTip.text}
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div>
        </FormHeaderLayout>
    );
}