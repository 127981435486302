import axios from 'axios'; 
import { AxiosResponse, AxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { IUserPreference } from '../../interfaces';
import { userPreferenceUpdate, userPreferencePending, userPreferenceError } from '../../state/reducers/userPreferenceSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_IDENTITY

export function updateUserPreference(userPreference: IUserPreference) {    
    return (dispatch:(arg0: PayloadAction<any>)=> any) => {        
        dispatch(userPreferencePending());
        axios.post(`${API_URL}/UserPreferences/`, userPreference)
        .then((res: AxiosResponse) => {
            dispatch(userPreferenceUpdate(res.data.data))
        }).catch((error: AxiosError) => {
            dispatch(userPreferenceError(error.response?.data))
        })
    }
}

export const getPreferences = createAsyncThunk('Preferences/getPreferences',
async (loginEmail: string, { rejectWithValue }) => {    
    try{        
        const res = await fetch(`${API_URL!}/UserPreferences/getUserPreferences?userName=${encodeURIComponent(loginEmail)}`);
        const data = await res.json();        

        return data;
    } catch (err: any) {        
        return rejectWithValue(err.response.data);
    }
})