import VerifyLoginForm from '../../components/VerifyLoginForm';
import MainLayout from '../../components/Layout/mainLayout';



export default function VerifyLogin(){

  return(                  
    <MainLayout>
      <VerifyLoginForm />
    </MainLayout>

  )  

}