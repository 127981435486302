import React, {  useRef, useMemo } from 'react';
import { SpinnerComponent } from 'react-element-spinner';
import { Button, Form } from 'react-bootstrap';
import { IAppState } from '../../state';
import { loginUser } from '../../api/User';
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { ILoginDetails, IUser, IUserPreference, IUserPreferenceResponse } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks'
import { loginReset,loginUpdate } from '../../state/reducers/loginSlice'
import StandardHeaderLayout from '../Layout/standardHeader';
import { LOGIN_DETAILS } from 'common/shared';

export default function LoginForm() {

    const
        dispatch = useAppDispatch(),
        user: {content: IUser, error?: any, loading: boolean, isLoggedIn: boolean} = useAppSelector(({ loginSlice }: IAppState) => loginSlice),    
        preferences: { content: [IUserPreference], error?: any, loading: boolean, updated: IUserPreferenceResponse} = useAppSelector(({ userPreferenceSlice }: IAppState) => userPreferenceSlice),    
        [data, setData] = React.useState<ILoginDetails>(LOGIN_DETAILS),
        [emailAddress, setEmailAddress] = React.useState(""),
        [rememberMe, setRememberMe] = React.useState<boolean | undefined>(false),
        [warning, setWarning] = React.useState(""),
        runOnce = useRef(false),
        cookies = useMemo(() => new Cookies(),[]), 
        activeFieldRef = React.useRef<HTMLInputElement>(null),
        [activeField, setactiveField] = React.useState(0),
        navigate = useNavigate();

        

    React.useEffect(() => {
        if(!runOnce.current){
            var email = cookies.get(`login-email-address`);
            var passwordless = cookies.get(`go-passwordless`)
           
            if(email){                
                setRememberMe(true);
                setEmailAddress(email);
                setData({
                    username: email,
                    password: data.password,
                    authProvider: null
                });
                setactiveField(1);
                if(passwordless==="true"){
                var emailUser: IUser = {
                    provider: "Authenticator",
                    twoStepAuthenticationIsRequired: true,
                    username: email,
                    token: "",
                    expiration: ""
                }                    
                    dispatch(loginUpdate(emailUser));                    
                    return;
                }                                                                                   
            } 
            dispatch(loginReset());                        
            runOnce.current=true;
        }
    },[dispatch, cookies, data.password])

    React.useEffect(() => {
        var email = cookies.get(`login-email-address`);
        if(!runOnce.current && (user.content.username!=='' && user.content.username===email)){            
            var passwordless = cookies.get(`go-passwordless`);
            if(passwordless==="true"){
                navigate("/verify-login");
            }            
        }
    }, [user, navigate, cookies])

    React.useEffect(() =>{
        activeFieldRef.current && activeFieldRef.current.focus();
    },[activeFieldRef])


    React.useEffect(() => { 
        if(runOnce.current){                       
            if((user.content.username !=="" && user.content.username !== undefined) && !user.content.twoStepAuthenticationIsRequired){                                                              
                if(user.content.expiration !== ''){
                    //Redux has persisted user and the expiration is filled out                                                                    
                    var today = new Date();
                    var expiry = new Date(user.content.expiration);
                    if (today > expiry){                                      
                        //Token has expired update user to remove all but the username                                   
                        dispatch(loginReset());                                    
                    }
                    else{
                        let token = localStorage.getItem("token");
                        if(token && token !==undefined){                            
                            //Token exists in local storage                            
                            navigate("/");
                        }
                        else {                            
                            dispatch(loginReset());               
                        }
                    }                  
                }
                else{                                    
                    dispatch(loginReset());                    
                }                                                                                      
            }
            else if (user.content.twoStepAuthenticationIsRequired){                
                navigate("/verify-login");
            }
            else if(user.error !== null) {
                setWarning("Email or password not recognised.");                
                cookies.remove("login-email-address");
                activeField===1 ? setactiveField(0) : setactiveField(1);                
                dispatch(loginReset);
            }            
        }                                                      
    }, [user, navigate, dispatch, activeField, cookies, preferences]);


    const handleEmailChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setData({
            username: event.target.value,
            password: data.password,
            authProvider: null
        });
        setEmailAddress(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setData({
            username: data.username,
            password: event.target.value,
            authProvider: null
        })
    }

    const handleSubmission = () => {        
        const current = new Date();        
        var year = current.getFullYear();
        var month = current.getMonth();
        var day = current.getDay();
        var newDate = new Date(year + 5, month + 1, day);                     
        cookies.remove(`login-email-address`);  
        if(rememberMe){                      
            cookies.set(`login-email-address`,data.username, {path: '/',expires: newDate});}
        else{            
            cookies.set(`login-email-address`,data.username, {path: '/'});
        }
        dispatch(loginUser(data));

    },
    handleForgottenPassword = () => {
        alert("Handle forgotten passwords.")
    },    
    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {            
        
        if (e.key === "Enter")//enter
        {
            if(e.currentTarget.id==="password")
                handleSubmission();
            else
                setactiveField(1);
        }
    };

    return (
        <StandardHeaderLayout header='Login'>
            <SpinnerComponent
                loading={user.loading}
                position='centered'
                message="Verifying your account details..."
            />
            <div className="text-danger lead font-weight-bold">{warning}</div>
            <div>
                <Form onSubmit={handleSubmission}>
                    <Form.Group className="mb-2">
                        <Form.Control
                            placeholder='Email address'
                            required
                            size="lg"
                            type="text"
                            id="userName"                            
                            onKeyPress={handleKeyPress}
                            ref={activeField===0 ? activeFieldRef : null}
                            value={emailAddress || ""}
                            onChange={ handleEmailChange }
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Control
                            placeholder="Password"
                            type="password"
                            required
                            size="lg"
                            id="password"
                            onKeyPress={handleKeyPress}
                            ref={activeField===1 ? activeFieldRef : null}
                            onChange={ handlePasswordChange }
                        />
                    </Form.Group>
                    <Form.Group>
                        <div>
                            <Form.Check                                
                                inline
                                label="Remember Me?"     
                                checked={rememberMe}
                                onChange = {e => setRememberMe(e.target.checked)}                           
                            />                                
                        </div>
                        </Form.Group>
                    <div className='flexbox grid button-padding' >                        
                        <Button variant="primary" size="lg" className="btn-block me-2" type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); handleSubmission() }} >Continue</Button>
                        <Button variant="secondary" size="lg" className="btn-block" onClick={e => { e.preventDefault(); e.stopPropagation(); handleForgottenPassword() }}>Forgot Password?</Button>                        
                    </div>
                </Form>
            </div>
        </StandardHeaderLayout>
    );

};
